import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './FriendsList.css'
import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg'
import { ReactComponent as EditProfileIcon } from '../../../assets/edit-profile.svg'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../context/AuthContext'

const FriendsList = ({
	selectedFriend,
	onSelectFriend,
	onFindNew,
	isSearching,
	setShowWaitingRoom,
	messages,
	onlineStatuses,
}) => {
	const [friends, setFriends] = useState([])
	const [unreadCounts, setUnreadCounts] = useState({})
	const { user, logoutAndRedirect } = useAuth()
	const navigate = useNavigate()
	const [lastMessages, setLastMessages] = useState({})
	const [lastMessageDates, setLastMessageDates] = useState({})

	useEffect(() => {
		const fetchFriends = async () => {
			try {
				const { data } = await axiosInstance.get('/friends')
				setFriends(data)

				const initialLastMessages = {}
				const initialLastMessageDates = {}
				for (const friend of data) {
					const response = await axios.get(
						`https://findalike.me/api/messages/last?user1=${user._id}&user2=${friend._id}`,
						{ withCredentials: true }
					)
					const lastMessage = response.data

					initialLastMessages[friend._id] = lastMessage?.content || ''
					initialLastMessageDates[friend._id] = new Date(lastMessage?.createdAt || 0)
				}
				setLastMessages(initialLastMessages)
				setLastMessageDates(initialLastMessageDates)
			} catch (error) {
				if (error.response && error.response.status === 401) {
					console.error('Nieautoryzowany dostęp, wylogowywanie...')
					logoutAndRedirect()
				} else {
					console.error('Błąd podczas pobierania znajomych:', error)
				}
			}
		}

		fetchFriends()
	}, [navigate, user._id])

	useEffect(() => {
		if (messages.length > 0) {
			const latestMessage = messages[messages.length - 1]
			const friendId = latestMessage.sender === user._id ? latestMessage.receiver : latestMessage.sender

			setLastMessages(prev => ({
				...prev,
				[friendId]:
					latestMessage.content.length > 6 ? latestMessage.content.substring(0, 6) + '...' : latestMessage.content,
			}))

			setLastMessageDates(prev => ({
				...prev,
				[friendId]: new Date(latestMessage.createdAt),
			}))
		}
	}, [messages])

	const sortedFriends = friends.slice().sort((a, b) => {
		const dateA = lastMessageDates[a._id] || new Date(0)
		const dateB = lastMessageDates[b._id] || new Date(0)
		return dateB - dateA
	})

	const handleLogout = () => {
		logoutAndRedirect()
		toast.success('Pomyślnie wylogowano')
	}

	const handleSelectFriend = friend => {
		setUnreadCounts(prev => ({
			...prev,
			[friend._id]: 0,
		}))
		onSelectFriend(friend)
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen().catch(err => {
				console.error('Błąd podczas włączania trybu pełnoekranowego:', err)
			})
		}
	}

	const handleFindNewClick = () => {
		if (!isSearching) {
			onFindNew()
		} else {
			setShowWaitingRoom(true)
		}
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen().catch(err => {
				console.error('Błąd podczas włączania trybu pełnoekranowego:', err)
			})
		}
	}

	return (
		<div className="friends-list__container">
			<div>
				<div className="friends-list__profile-header">
					<div className="friends-list__profile-info" onClick={() => navigate('/edit-profile')}>
						<img src={user.profilePicture} alt={user.name} className="friends-list__profile-picture no-border" />
						<div className="friends-list__user-details">
							<span className="friends-list__user-name">{user.name}</span>
							<EditProfileIcon className="friends-list__edit-profile-icon" />
						</div>
					</div>
					<LogoutIcon className="friends-list__logout-icon" onClick={handleLogout} />
				</div>
				<button className="primary__button" onClick={handleFindNewClick}>
					{isSearching ? 'Szukanie znajomego...' : 'Znajdź znajomego'}
				</button>

				<h5 className="friends-list__friends__list__title">Twoi znajomi</h5>
				{sortedFriends.length > 0 ? (
					<ul className="friends-list__list">
						{sortedFriends.map(friend => (
							<li
								key={friend._id}
								className={`friends-list__item ${selectedFriend && selectedFriend._id === friend._id ? 'active' : ''}`}
								onClick={() => handleSelectFriend(friend)}>
								<div
									className={`friends-list__item__status ${
										onlineStatuses[friend._id] === 'online' ? 'green-border' : 'gray-border'
									}`}>
									<img src={friend.profilePicture} alt={friend.name} className="friends-list__profile-picture pfp099" />
									<span
										className={`friends-list__status-icon ${
											onlineStatuses[friend._id] === 'online' ? 'online' : 'offline'
										}`}></span>
								</div>
								<div className="friends-list__details">
									<span className="friends-list__name">{friend.name}</span>
									<span className="friends-list__last-message">{lastMessages[friend._id]}</span>
									{unreadCounts[friend._id] > 0 && (
										<span className="friends-list__unread-count">{unreadCounts[friend._id]}</span>
									)}
								</div>
							</li>
						))}
					</ul>
				) : (
					<p className="friends-list-p">Nie znaleziono znajomych.</p>
				)}
			</div>
		</div>
	)
}

export default FriendsList
