import React from 'react'
import './Home.css'
import screenPC from '../../../assets/screenPC.png'
import screenMobile from '../../../assets/screenMobile.png'
import Footer from '../../UI/Footer/Footer'
import HowItWorks from '../How/How.js'
import Logo from '../../../assets/logo.png'
import { Link } from 'react-router-dom'
import AnnoucmentBar from '../../UI/Navbar/AnnoucmentBar.js'
const Home = () => {
	return (
		<>
			<AnnoucmentBar />
			{/* <Navbar /> */}
			<header className="home__header" id="home">
				<div className="blur-div"></div>
				<img src={Logo} className="header_logo" />
				<h1>
					<div>
						FindAlike.<b>Me</b>
					</div>
					<div className="home__together">
						<div id="home__dynamic-text">
							<div className="home__dynamic-text-div-text home__blue">Śmiej 😄</div>
							<div className="home__dynamic-text-div-text home__gray">Posmuć 💀</div>
							<div className="home__dynamic-text-div-text home__pink">Rozwijaj 🧠</div>
							<div className="home__dynamic-text-div-text home__white">Inspiruj 👀</div>
							<div className="home__dynamic-text-div-text home__orange">Ucz 🤯</div>
							<div className="home__dynamic-text-div-text home__green">Ciesz 😄</div>
							<div className="home__dynamic-text-div-text home__purple">Pomyl 🙂‍↔️</div>
						</div>
						<div>
							<span>Się </span>{' '}
							<span>
								<b className="home__b-main"> Razem</b>
							</span>
						</div>
					</div>
				</h1>

				<img src={screenPC} className="hero__img" alt="Hero" />
				<img src={screenMobile} className="hero__img__mobile" alt="Hero" />
			</header>
			<HowItWorks />
			<section className="call-to-action__section">
				<div className="headline">
					<h2>"Wymień Doświadczenia, Znajdź Wsparcie"</h2>
					<p>
						"Wymień Doświadczenia, Znajdź Wsparcie" to więcej niż tylko slogan – to przestrzeń, gdzie możesz spotkać
						osoby przechodzące przez podobne wyzwania. Nasza platforma oferuje otwarte i bezpieczne środowisko dla
						wymiany doświadczeń i budowania wartościowych relacji. Dołącz do naszej społeczności, gdzie zrozumienie i
						wsparcie idą w parze."
					</p>
				</div>
				<Link to="/register">
					<button className="primary__button btn-max-w">
						Zacznij teraz{' '}
						<svg fill="#fff" width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" />
							<circle cx="8.5" cy="10.5" r="1.5" />
							<circle cx="15.493" cy="10.493" r="1.493" />
							<path d="M12 18c4 0 5-4 5-4H7s1 4 5 4z" />
						</svg>
					</button>
				</Link>
			</section>
			<Footer />
		</>
	)
}

export default Home
