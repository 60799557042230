import React from 'react'
import axios from 'axios'
import './WaitingRoom.css'
import { toast } from 'react-toastify'

const WaitingRoom = ({ onCancelSearch }) => {
	const handleCancelSearch = async () => {
		try {
			await axios.delete(`https://findalike.me/api/cancel-search`, { withCredentials: true })
			toast.success('Anulowano szukanie.')
			onCancelSearch() // Notify parent to update the state
		} catch (error) {
			console.error('Error cancelling search', error)
		}
	}

	return (
		<div className="waiting-room__container">
			<div className="waiting-room__loader"></div>
			<h2>Czekanie na drugą osobę...</h2>
			<button className="secondary__button" onClick={handleCancelSearch}>
				Anuluj szukanie
			</button>
		</div>
	)
}

export default WaitingRoom
