import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CustomSelect from './CustomSelect'
import './FindNew.css'
import { toast } from 'react-toastify'

const FindNew = ({ onSubmit }) => {
	const [category, setCategory] = useState('')
	const [specificIssue, setSpecificIssue] = useState('')
	const [friendsAdded, setFriendsAdded] = useState(0)
	const [activeButton, setActiveButton] = useState('choice')
	const [submitButtonText, setSubmitButtonText] = useState('Szukaj znajomego')

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const { data } = await axios.get(`https://findalike.me/api/auth/user-info`, {
					withCredentials: true,
				})
				setFriendsAdded(data.FriendsAdded)
			} catch (error) {
				console.error('Error fetching user data', error)
			}
		}
		fetchUserData()
	}, [])

	useEffect(() => {
		if (friendsAdded >= 99) {
			setSubmitButtonText('Limit znajomych osiągnięty')
			return
		}
		if (activeButton === 'choice') {
			if (!specificIssue || !category || specificIssue === 'Wybierz dokładny problem') {
				setSubmitButtonText('Odpowiedz na wszystkie pytania')
			} else {
				setSubmitButtonText('Szukaj znajomego')
			}
		} else if (activeButton === 'description') {
			if (specificIssue.trim().split(/\s+/).length < 10) {
				setSubmitButtonText('Napisz co najmniej 10 słów')
			} else {
				setSubmitButtonText('Szukaj znajomego')
			}
		}
	}, [specificIssue, category, activeButton, friendsAdded])

	const handleCategoryChange = value => {
		setCategory(value)
		setSpecificIssue('Wybierz dokładny problem') // Reset specificIssue when the category changes
	}

	const handleButtonClick = button => {
		setActiveButton(button)
	}

	const submitHandler = async e => {
		e.preventDefault()

		try {
			toast.info('Szukanie znajomego...')
			const { data } = await axios.post(
				`https://findalike.me/api/submit-response`,
				{
					question: 'Jaki jest Twój problem?',
					answer: specificIssue,
					category,
				},
				{ withCredentials: true }
			)

			if (!data.matchFound) {
				onSubmit(data)
			} else {
				window.location.reload() // Or handle redirection in a more user-friendly way
			}
		} catch (error) {
			if (error.response && error.response.status === 400 && error.response.data.allowCancel) {
				alert(
					`Masz już aktywną odpowiedź w kategorii: ${error.response.data.existingResponse.category}. Możesz anulować szukanie.`
				)
			} else {
				console.error('Error submitting response', error.response ? error.response.data : error.message)
			}
		}
	}

	const containerClass = `choice__findnew ${activeButton === 'choice' ? 'active-choice' : 'active-description'}`
	const buttonStyle = friendsAdded >= 99 ? { opacity: 0.7, cursor: 'not-allowed' } : {}
	const isSubmitDisabled = () => {
		if (friendsAdded >= 99) return true
		if (activeButton === 'choice') return !specificIssue || !category || specificIssue === 'Wybierz dokładny problem'
		if (activeButton === 'description') return specificIssue.trim().split(/\s+/).length < 10
		return false
	}

	const buttonLabel = isSubmitDisabled() ? submitButtonText : `${submitButtonText} ${friendsAdded}/99`

	// Array of questions based on the selected category
	const issueOptions = {
		Kompleksy: [
			'Kompleks Wzrostu',
			'Kompleks Wyglądu (np. nosa, uszu, zębów)',
			'Kompleks sylwetki',
			'Kompleks Inteligencji',
			'Kompleks Umiejętności społecznych',
			'Kompleks Wiekowy',
			'Kompleks Edukacyjny',
			'Kompleks Zawodowy',
			'Kompleks Seksualny',
			'Kompleks Zdrowotny',
		],
		'Orientacja seksualna | Nastolatkowie ': [
			'Pytania o orientację seksualną',
			'Niepełne zrozumienie orientacji seksualnej',
			'Problemy z akceptacją orientacji seksualnej',
			'Problemy z akceptacją orientacji seksualnej przez innych',
			'Czucie się Gejem',
			'Czucie się Lesbijką',
			'Czucie się Biseksualnym',
			'Czucie się Aseksualnym',
			'Czucie się Panseksualnym',
			'Czucie się Queer',
			'Czucie się Inaczej i Chce pogadać',
		],
		'Orientacja seksualna | Pełnoletni': [
			'Pytania o orientację seksualną',
			'Niepełne zrozumienie orientacji seksualnej',
			'Problemy z akceptacją orientacji seksualnej',
			'Problemy z akceptacją orientacji seksualnej przez innych',
			'Czucie się Gejem',
			'Czucie się Lesbijką',
			'Czucie się Biseksualnym',
			'Czucie się Aseksualnym',
			'Czucie się Panseksualnym',
			'Czucie się Queer',
			'Czucie się Inaczej i Chce pogadać',
		],
		Uzalezienia: [
			'Uzależnienie od TikToka, Instagrama',
			'Uzależnienie od telefonu (Ogólnie)',
			'Uzałeznienie od gier komputerowych',
			'Uzależnienie od internetu',
			'Uzależnienie od alkoholu',
			'Uzależnienie od narkotyków',
			'Uzależnienie od hazardu',
			'Uzależnienie od seksu',

			'Uzależnienie od zakupów',
			'Uzależnienie od pracy',
			'Uzależnienie od ćwiczeń',
			'Uzależnienie od jedzenia',
		],
		Stres: [
			'Stres w szkole',
			'Stres w pracy',
			'Stres finansowy - obawy o stabilność finansową',
			'Stres związany z relacjami - np: kłotnie w rodzinie, trudne sytuacje w rodzinie',
		],
		Samotność: [
			'Samotność',
			'Brak znajomych, przyjaciół',
			'Samotność w związku - poczucie braku emocjonalnego wsparcia od partnera',
			'Znajomi, ale nie czuję się z nimi związany',
			'Brak wsparcia w trudnych chwilach',
			'Mam wrazenie ze nikt mnie nie rozumie',
		],
		Depresja: [
			'Samoocena',
			'Zaburzenia koncentracji',
			'Zaburzenia nastroju',
			'Brak motywacji',
			'Brak radości z życia',
			'Poczucie beznadziejności',
			'Myśli samobójcze',
			'Zaburzenia snu',
			'Zaburzenia apetytu',
			'Zbyt dużo snu lub bezsenność',
			'Wycofanie się z relacji',
			'Rozdrażnienie',
			'Płaczliwość',
			'Negatywizm',
			'Samokaleczenie',
		],
		Lęk: [
			'Lęk przed porażką',
			'Lęk przed porażką w biznesie',
			'Lęk przed oceną innych',
			'Lęk przed odrzuceniem',
			'Lęk przed samotnością',
			'Lęk przed przyszłością',
			'Lęk przed zmianami',
		],
		Zdrowie: [
			'Zdrowie psychiczne',
			'Zdrowie fizyczne',
			'Problemy z wagą',
			'Problemy z jedzeniem',
			'Problemy z alkoholem',
			'Problemy z narkotykami',
			'Problemy z paleniem',
			'Otyłość',
			'Anoreksja',
			'Problemy z dopalaczami',
		],
		Rodzina: [
			'Problemy w rodzinie',
			'Kłótnie z rodzeństwem',
			'Kłótnie z rodzicami',
			'Kłótnie z partnerem',
			'Kłótnie z dziećmi',
			'Kłótnie z teściami',
		],

		Presja: [
			'Presja od społeczeństwa w kontekście pieniędzy',
			'Presja społeczna',
			'Presja w szkole',
			'Presja w pracy',
			'Presja związana z oczekiwaniami rodziny',
			'Presja rówieśnicza',
		],

		Praca: [
			'Problemy w pracy',
			'Mobbing',
			'Zwolnienie z pracy',
			'Problemy z przełożonymi',
			'Problemy z kolegami w pracy',
			'Problemy z pracą zdalną',
		],
		'Ogólne - Uczucia': ['Zazdrość', 'Złość', 'Smutek', 'Sczęście', 'Miłość', 'Nienawiść', 'Zawód'],
		Związki: ['Problemy w związku', 'Zdrada', 'Aktywność seksualna'],
		'Utrata bliskiej osoby': ['Utrata bliskiej osoby', 'Utrata zwierzęcia domowego'],
		'Media społecznościowe': [
			'Problem z korzystaniem z mediów społecznościowych',
			'Presja idealnego życia w mediach społecznościowych',
		],
		Cyberprzemoc: [
			'Cyberprzemoc',
			'Problem z korzystaniem z mediów społecznościowych',
			'Presja idealnego życia w mediach społecznościowych',
		],
		Przemoc: ['Przemoc fizyczna', 'Przemoc psychiczna', 'Przemoc seksualna', 'Przemoc w rodzinie'],
		'Problemy z jedzeniem': ['Anoreksja', 'Bulimia', 'Nadwaga', 'Otyłość', 'Problemy z jedzeniem', 'Problemy z wagą'],
	}

	return (
		<div className="find-new__container">
			<div className={containerClass}>
				<button className={activeButton === 'choice' ? 'active' : ''} onClick={() => handleButtonClick('choice')}>
					Wybór
				</button>
				<button
					className={activeButton === 'description' ? 'active' : ''}
					onClick={() => handleButtonClick('description')}
					disabled>
					Opis
				</button>
			</div>
			<form className="find-new__form" onSubmit={submitHandler}>
				{activeButton === 'choice' && (
					<>
						<label>
							Wybierz kategorię problemu:
							<CustomSelect
								options={Object.keys(issueOptions)}
								value={category}
								onChange={handleCategoryChange}
								placeholder="Wybierz kategorię"
							/>
						</label>

						{category && (
							<label>
								Określ problem:
								<CustomSelect
									options={issueOptions[category]}
									value={specificIssue}
									onChange={setSpecificIssue}
									placeholder="Wybierz dokładny problem"
								/>
							</label>
						)}
					</>
				)}

				<button type="submit" disabled={isSubmitDisabled()} style={buttonStyle} className="secondary__button">
					{buttonLabel}
				</button>
			</form>
		</div>
	)
}

export default FindNew
