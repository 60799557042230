import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './Register.css'
import { ReactComponent as ShowIcon } from '../../../assets/eye-solid.svg'
import { ReactComponent as HideIcon } from '../../../assets/eye-slash-solid.svg'
import { toast } from 'react-toastify'
import leoProfanity from 'leo-profanity'

const Register = () => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [profilePicture, setProfilePicture] = useState('')
	const [error, setError] = useState('')
	const [nameAvailable, setNameAvailable] = useState(null)
	const [emailError, setEmailError] = useState(null)
	const [passwordStrength, setPasswordStrength] = useState('')
	const [passwordVisible, setPasswordVisible] = useState(false)
	const [passwordMatch, setPasswordMatch] = useState(true)
	const [nameError, setNameError] = useState('')

	const { register } = useAuth()

	const customBlacklist = ['kurwa']

	leoProfanity.loadDictionary()

	customBlacklist.forEach(word => leoProfanity.add(word))

	useEffect(() => {
		if (name) {
			const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${encodeURIComponent(name)}`
			setProfilePicture(avatarUrl)
		}
	}, [name])

	useEffect(() => {
		validateEmail(email)
	}, [email])

	useEffect(() => {
		validatePassword(password)
	}, [password])

	useEffect(() => {
		validatePasswordMatch()
	}, [password, confirmPassword])

	const checkUsernameAvailability = async name => {
		try {
			const { data } = await axios.get(`https://findalike.me/api/auth/check-username`, {
				params: { name },
			})
			setNameAvailable(!data.exists)
		} catch (error) {
			console.error('Błąd sprawdzania dostępności nazwy użytkownika', error)
			setNameAvailable(null)
		}
	}

	const validateEmail = async email => {
		if (email.trim() === '') {
			setEmailError(null)
			return
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (emailRegex.test(email)) {
			try {
				const { data } = await axios.get(`https://findalike.me/api/auth/check-email`, {
					params: { email },
				})
				if (data.exists) {
					setEmailError('Email jest już zajęty, zaloguj się jeśli to ty')
				} else {
					setEmailError(null)
				}
			} catch (error) {
				console.error('Błąd sprawdzania dostępności emaila', error)
				setEmailError('Błąd weryfikacji emaila')
			}
		} else {
			setEmailError('Niepoprawny email')
		}
	}

	const validatePassword = password => {
		let strength = ''
		if (password.length >= 6) {
			if (/[A-Z]/.test(password) && /\d/.test(password)) {
				if (password.length >= 12) {
					strength = 'Bardzo silne'
				} else if (password.length >= 8) {
					strength = 'Silne'
				} else {
					strength = 'Ok'
				}
			} else {
				strength = 'Słabe'
			}
		} else {
			strength = 'Za krótkie'
		}
		setPasswordStrength(strength)
	}

	const validatePasswordMatch = () => {
		setPasswordMatch(password === confirmPassword)
	}

	const togglePasswordVisibility = () => {
		setPasswordVisible(!passwordVisible)
	}

	const handleNameChange = event => {
		const newName = event.target.value
		setName(newName)

		const containsForbiddenWord = customBlacklist.some(word => newName.toLowerCase().includes(word))

		if (leoProfanity.check(newName) || containsForbiddenWord) {
			setNameError('Nazwa użytkownika zawiera zabronione słowa.')
		} else {
			setNameError('')
			checkUsernameAvailability(newName)
		}
	}

	const isFormValid = () => {
		return (
			name &&
			email &&
			password &&
			confirmPassword &&
			!nameError &&
			!emailError &&
			passwordMatch &&
			passwordStrength !== 'Za krótkie' &&
			passwordStrength !== 'Słabe' &&
			nameAvailable
		)
	}

	const submitHandler = async e => {
		e.preventDefault()
		if (!passwordMatch) {
			setError('Hasła nie są identyczne')
			toast.error('Hasła nie są identyczne')
			return
		}
		if (!nameAvailable) {
			setError('Nazwa użytkownika jest zajęta')
			toast.error('Nazwa użytkownika jest zajęta')
			return
		}
		if (emailError) {
			setError(emailError)
			toast.error('Niepoprawny email')
			return
		}
		if (nameError) {
			setError(nameError)
			toast.error(nameError)
			return
		}
		try {
			await register(name, email, password, profilePicture)
			toast.success('Zarejestrowano pomyślnie')
		} catch (error) {
			setError('Błąd rejestracji')
			toast.error('Błąd rejestracji')
		}
	}

	return (
		<div className="register__auth">
			<div className="register__image-auth">
				<div className="register__image-auth-shadow"></div>
			</div>
			<div className="register__form-auth">
				<div className="register__profile-picture-selection">
					<img src={profilePicture} alt="" />
				</div>
				<h1>Zarejestruj się</h1>
				<form className="register__form" onSubmit={submitHandler}>
					{error && <div className="register__error-message">{error}</div>}

					<div className="register__input-container">
						{nameError && <div className="register__error-message">{nameError}</div>}
						<input
							className={`register__input ${nameError ? 'register__input-error' : ''}`}
							type="text"
							value={name}
							onChange={handleNameChange}
							placeholder="Nazwa użytkownika"
						/>
						{nameAvailable === false && <div className="register__error-message">Nazwa jest zajęta</div>}
					</div>

					<div className="register__input-container">
						<input
							className={`register__input ${emailError ? 'register__input-error' : ''}`}
							type="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder="Email"
						/>
						{emailError && <div className="register__error-message">{emailError}</div>}
					</div>

					<div className="register__input-container register__input-container--margin-none">
						<div className="register__input-container-child">
							<input
								className="register__input"
								type={passwordVisible ? 'text' : 'password'}
								value={password}
								onChange={e => setPassword(e.target.value)}
								placeholder="Hasło"
							/>
							<div className="register__password-toggle-icon" onClick={togglePasswordVisibility}>
								{passwordVisible ? <ShowIcon width="19px" /> : <HideIcon width="20px" />}
							</div>
						</div>
						<div className="register__password-requirements">
							Musi zawierać co najmniej 6 znaków
							{password && (
								<div className={`register__password-strength ${passwordStrength.replace(' ', '-').toLowerCase()}`}>
									{passwordStrength}
								</div>
							)}
						</div>
					</div>

					<div className="register__input-container">
						<input
							className={`register__input ${!passwordMatch ? 'register__input-error' : ''}`}
							type="password"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							placeholder="Powtórz hasło"
						/>
						{!passwordMatch && confirmPassword && (
							<div className="register__error-message">Hasła nie są identyczne!</div>
						)}
					</div>
					<button className="register__button" type="submit" disabled={!isFormValid()}>
						Utwórz konto
					</button>
				</form>
				<p>
					Masz już konto?{' '}
					<span>
						<Link to="/login">Zaloguj się</Link>
					</span>
				</p>
			</div>
		</div>
	)
}

export default Register
