import React from 'react'
import './Footer.css'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-content">
				<div className="footer-section about">
					<h2 className="logo">
						FindAlike<span>Me</span>
					</h2>

					<div className="socials">
						<a href="/">
							<FaFacebookF />
						</a>
						<a href="/">
							<FaTwitter />
						</a>
						<a href="/">
							<FaInstagram />
						</a>
						<a href="/">
							<FaLinkedinIn />
						</a>
					</div>
				</div>

				<div className="footer-section links">
					<h2>Szybkie linki</h2>
					<ul>
						<li>
							<a href="#home">Home</a>
						</li>
						<li>
							<a href="#info">O nas</a>
						</li>
						<li>
							<a href="#whyus">Dlaczego My?</a>
						</li>
						<li>
							<a href="#faq">FAQ</a>
						</li>
					</ul>
				</div>

				<div className="footer-section contact">
					<h2>Kontakt</h2>
					<ul>
						<li>Telefon: +48 698 404 252</li>
						<li>Email: findalikeme@gmail.com</li>
						<li>Sociale : @findalikeme</li>
					</ul>
				</div>
			</div>

			<div className="footer-bottom">
				<p>&copy; 2024 FindAlikeMe | NIP 571 172 78 74</p>
			</div>
		</footer>
	)
}

export default Footer
