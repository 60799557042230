import React from 'react'
import './How.css'

function How() {
	return (
		<div className="how-it-works-section" id="info">
			<div className="headline headline-center">
				<h2>Jak to działa?</h2>
			</div>
			<div className="step-container">
				<div className="step">
					<div className="step-count">1</div>
					<div className="step-text">
						<h3>Załóż darmowe konto</h3>
						<p>
							Kliknij "Zacznij teraz" i wypełnij kilka prostych informacji. To zajmie tylko 2 minuty, a już za chwilę
							będziesz gotowy do rozpoczęcia swojej przygody.
						</p>
					</div>
				</div>
				<div className="step">
					<div className="step-count">2</div>
					<div className="step-text">
						<h3>Dołącz do Chatu</h3>
						<p>
							Po założeniu konta, przejdź do Chatu klikając "Chat". Następnie wybierz "Szukaj nowego znajomego" i
							odpowiedz na kilka krótkich pytań, które pomogą nam lepiej Cię poznać.
						</p>
					</div>
				</div>
				<div className="step">
					<div className="step-count">3</div>
					<div className="step-text">
						<h3>Zacznij rozmowę</h3>
						<p>
							Już po chwili zostaniesz połączony z osobą, która doskonale rozumie Twoje wyzwania. Razem możecie dzielić
							się radami, doświadczeniami i wspierać się nawzajem w dążeniu do rozwiązania problemu.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default How
