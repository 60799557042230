import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { Link } from 'react-router-dom'
import './Login.css'
import { toast } from 'react-toastify'

const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState('')
	const [emailValid, setEmailValid] = useState(true)
	const [passwordValid, setPasswordValid] = useState(true)

	const { login } = useAuth()

	const validateEmail = email => {
		const re = /\S+@\S+\.\S+/
		return re.test(email)
	}

	const validatePassword = password => {
		return password.length >= 6
	}

	const submitHandler = async e => {
		e.preventDefault()

		const isEmailValid = validateEmail(email)
		const isPasswordValid = validatePassword(password)

		setEmailValid(isEmailValid)
		setPasswordValid(isPasswordValid)

		if (!isEmailValid || !isPasswordValid) {
			setError('Wprowadzone dane są niepoprawne.')
			toast.error('Wprowadzone dane są niepoprawne.')
			return
		}

		try {
			await login(email, password)
			toast.success('Zalogowano pomyślnie')
		} catch (error) {
			if (error.message === 'Nieprawidłowy email lub hasło') {
				setError('Nieprawidłowy email lub hasło')
				toast.error('Nieprawidłowy email lub hasło')
			} else {
				setError(error.message)
				toast.error(error.message)
			}
		}
	}

	// Ustawienie wartości disabled na podstawie walidacji email i hasła
	const isButtonDisabled = !emailValid || !passwordValid || !email || !password

	return (
		<div className="login__auth">
			<div className="login__image-auth">
				<div className="login__image-auth-shadow"></div>
			</div>
			<div className="login__form-auth">
				<h1>Zaloguj się</h1>
				<form className="login__form" onSubmit={submitHandler}>
					{error && (
						<div className="login__error-message">
							<span>{error}</span>
						</div>
					)}
					<div className="login__input-container">
						<input
							className={`login__input ${!emailValid ? 'login__input-error' : ''}`}
							type="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder="Email"
						/>
						{!emailValid && email && (
							<div className="login__error-message">
								<span>Niepoprawny format adresu email</span>
							</div>
						)}
					</div>

					<div className="login__input-container">
						<input
							className={`login__input ${!passwordValid ? 'login__input-error' : ''}`}
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							placeholder="Hasło"
						/>
						{!passwordValid && password && (
							<div className="login__error-message">Hasło musi mieć co najmniej 6 znaków</div>
						)}
					</div>

					<button className="login__button" type="submit" disabled={isButtonDisabled}>
						Zaloguj
					</button>
				</form>

				<p className="login__text__other">
					Nie masz konta?{' '}
					<span>
						<Link to="/register">Zarejestruj się</Link>
					</span>
				</p>
				<p className="login__text__other">
					Zapomniałeś hasła?{' '}
					<span>
						<Link to="/forgot-password">Zresetuj hasło</Link>
					</span>
				</p>
			</div>
		</div>
	)
}

export default Login
