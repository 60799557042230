import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import FriendsList from '../../Social/FriendList/FriendsList'
import FindNew from '../../Misc/FindNew/FindNew'
import WaitingRoom from '../../Misc/WaitingRoom/WaitingRoom'
import ChatWindow from '../ChatWindow/ChatWindow'
import FriendInfo from '../../Social/FriendInfo/FriendInfo'
import io from 'socket.io-client'
import Modal from '../../UI/Modal/Modal'
import './Chat.css'
import { axiosInstance } from '../../../context/AuthContext'

// Socket.io configuration
const socket = io('/', {
	withCredentials: true,
})
const ChatLayout = () => {
	const { user, logoutAndRedirect } = useAuth() // Correct usage of logoutAndRedirect
	const navigate = useNavigate()
	const [showFindNew, setShowFindNew] = useState(false)
	const [showWaitingRoom, setShowWaitingRoom] = useState(false)
	const [isSearching, setIsSearching] = useState(false)
	const [selectedFriend, setSelectedFriend] = useState(null)
	const [showFriendInfo, setShowFriendInfo] = useState(false)
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	const [messages, setMessages] = useState([])
	const [onlineStatuses, setOnlineStatuses] = useState({})

	const toggleFriendInfo = () => {
		setShowFriendInfo(prevState => !prevState)
	}

	const handleCloseFriendInfo = () => {
		setShowFriendInfo(false)
	}

	const toggleSidebar = () => {
		setIsSidebarOpen(prevState => !prevState)
	}

	const handleSelectFriend = friend => {
		setSelectedFriend(friend)
		setIsSidebarOpen(false)
		fetchMessages(friend._id)
	}

	const fetchMessages = async friendId => {
		try {
			const { data } = await axiosInstance.get(`/messages?user1=${user._id}&user2=${friendId}`)
			setMessages(data)
		} catch (error) {
			console.error('Error fetching messages:', error)
		}
	}

	useEffect(() => {
		const handleReceiveMessage = message => {
			setMessages(prevMessages => [...prevMessages, message])
		}

		const handleUserStatusUpdate = ({ userId, status }) => {
			setOnlineStatuses(prev => ({
				...prev,
				[userId]: status,
			}))
		}

		socket.on('receiveMessage', handleReceiveMessage)
		socket.on('userStatusUpdate', handleUserStatusUpdate)

		return () => {
			socket.off('receiveMessage', handleReceiveMessage)
			socket.off('userStatusUpdate', handleUserStatusUpdate)
		}
	}, [])

	const checkActiveSearch = async () => {
		try {
			const response = await axiosInstance.get('/active-search')

			if (response && response.data) {
				setIsSearching(response.data.isSearching)
			} else {
				console.warn('No data returned from active search check.')
			}
		} catch (error) {
			console.error('Error during active search check:', error.message || 'Unknown error occurred')
		}
	}

	useEffect(() => {
		let intervalId

		if (user) {
			socket.emit('join', user._id)

			intervalId = setInterval(checkActiveSearch, 5000)
			checkActiveSearch()

			socket.on('friendFound', data => {
				window.location.reload()
			})

			socket.on('friendRemoved', data => {
				window.location.reload()
			})

			socket.on('match', data => {
				if (data.message === 'You are already friends with this person') {
					alert('You are already friends with this person')
					navigate('/chat')
				} else {
					window.location.reload()
				}
			})
		}

		return () => {
			socket.off('friendFound')
			socket.off('friendRemoved')
			socket.off('match')
			clearInterval(intervalId)
		}
	}, [user, navigate])

	if (!user) {
		navigate('/login')
		return null
	}

	const handleFindNewSubmit = data => {
		if (data.matchFound) {
			window.location.reload()
		} else {
			setShowWaitingRoom(true)
			setShowFindNew(false)
			setIsSearching(true)
		}
	}

	const handleFindNew = () => {
		if (!isSearching) {
			setShowFindNew(true)
		} else {
			setShowWaitingRoom(true)
		}
	}

	const handleCloseWaitingRoom = () => {
		setShowWaitingRoom(false)
	}

	const handleCancelSearch = () => {
		setIsSearching(false)
		setShowWaitingRoom(false)
	}

	return (
		<div className="chat-layout__container">
			<Modal show={showFindNew} onClose={() => setShowFindNew(false)}>
				<FindNew onSubmit={handleFindNewSubmit} />
			</Modal>

			<Modal show={showWaitingRoom} onClose={handleCloseWaitingRoom}>
				<WaitingRoom onCancelSearch={handleCancelSearch} />
			</Modal>

			{/* Sidebar */}
			<div className={`chat-layout__sidebar ${isSidebarOpen ? 'chat-layout__sidebar--open' : ''}`}>
				<FriendsList
					selectedFriend={selectedFriend}
					onSelectFriend={handleSelectFriend}
					onFindNew={handleFindNew}
					isSearching={isSearching}
					setShowWaitingRoom={setShowWaitingRoom}
					messages={messages}
					onlineStatuses={onlineStatuses}
				/>
			</div>

			{/* Chat Window */}
			<div className="chat-layout__chat-window">
				<ChatWindow
					selectedFriend={selectedFriend}
					toggleFriendInfo={toggleFriendInfo}
					toggleSidebar={toggleSidebar}
					messages={messages}
					setMessages={setMessages}
				/>
			</div>

			{/* Friend Info */}
			<div className={`chat-layout__friend-info ${showFriendInfo ? 'chat-layout__friend-info--open' : ''}`}>
				<FriendInfo selectedFriend={selectedFriend} onClose={handleCloseFriendInfo} />
			</div>
		</div>
	)
}

export default ChatLayout
