import React, { useState, useEffect } from 'react'
import { useAuth, axiosInstance } from '../../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import './EditProfile.css'
import { toast } from 'react-toastify'
import leoProfanity from 'leo-profanity'

const EditProfile = () => {
	const { user } = useAuth()
	const [name, setName] = useState('')
	const [password, setPassword] = useState('')
	const [profilePicture, setProfilePicture] = useState('')
	const [description, setDescription] = useState('')
	const [error, setError] = useState('')
	const [charCount, setCharCount] = useState(0)
	const [nameError, setNameError] = useState('')
	const [passwordStrength, setPasswordStrength] = useState('')
	const navigate = useNavigate()

	// Inicjalizacja czarnej listy wulgaryzmów
	const customBlacklist = ['kurwa']

	leoProfanity.loadDictionary()
	customBlacklist.forEach(word => leoProfanity.add(word))

	// Inicjalizacja pól profilu po załadowaniu danych użytkownika
	useEffect(() => {
		if (user) {
			setName(user.name)
			setProfilePicture(user.profilePicture)
			setDescription(user.description)
			setCharCount(user.description ? user.description.length : 0)
		}
	}, [user])

	// Generowanie URL avatara przy zmianie nazwy
	useEffect(() => {
		if (name) {
			const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${encodeURIComponent(name)}`
			setProfilePicture(avatarUrl)
		}
	}, [name])

	// Walidacja nazwy użytkownika
	const validateName = newName => {
		const containsForbiddenWord = customBlacklist.some(word => newName.toLowerCase().includes(word))
		if (leoProfanity.check(newName) || containsForbiddenWord) {
			setNameError('Nazwa użytkownika zawiera zabronione słowa.')
		} else {
			setNameError('')
		}
	}

	// Walidacja hasła
	const validatePassword = password => {
		let strength = ''
		if (password.length >= 6) {
			if (/[A-Z]/.test(password) && /\d/.test(password)) {
				if (password.length >= 12) {
					strength = 'Bardzo silne'
				} else if (password.length >= 8) {
					strength = 'Silne'
				} else {
					strength = 'Ok'
				}
			} else {
				strength = 'Słabe'
			}
		} else {
			strength = 'Za krótkie'
		}
		setPasswordStrength(strength)
	}

	// Obsługa zmian w polu opisu i aktualizacja liczby znaków
	const handleDescriptionChange = e => {
		const input = e.target.value
		if (input.length <= 185) {
			setDescription(input)
			setCharCount(input.length)
		}
	}

	// Generowanie losowego avatara
	const generateRandomAvatar = () => {
		const randomSeed = Math.random().toString(36).substring(2, 15)
		const avatarUrl = `https://api.dicebear.com/9.x/thumbs/svg?seed=${randomSeed}`
		setProfilePicture(avatarUrl)
	}

	// Obsługa zmiany nazwy użytkownika
	const handleNameChange = event => {
		const newName = event.target.value
		setName(newName)
		validateName(newName)
	}

	// Obsługa zmiany hasła
	const handlePasswordChange = event => {
		const newPassword = event.target.value
		setPassword(newPassword)
		validatePassword(newPassword)
	}

	// Obsługa przesłania formularza
	const submitHandler = async e => {
		e.preventDefault()

		if (nameError || passwordStrength === 'Za krótkie' || passwordStrength === 'Słabe') {
			setError('Upewnij się, że wszystkie pola są prawidłowo wypełnione.')
			toast.error('Upewnij się, że wszystkie pola są prawidłowo wypełnione.')
			return
		}

		try {
			const { data } = await axiosInstance.put('/auth/profile', {
				name,
				password,
				profilePicture,
				description,
			})
			localStorage.setItem('userInfo', JSON.stringify(data))
			toast.success('Profil zaktualizowany pomyślnie, odśwież stronę aby zobaczyć zmiany.')
			navigate('/chat')
			window.location.reload()
		} catch (error) {
			console.error('Błąd przy aktualizacji profilu', error)
			toast.error('Coś poszło nie tak. Spróbuj ponownie później.')
			setError('Błąd przy aktualizacji profilu')
		}
	}

	// Przekierowanie na stronę logowania jeśli użytkownik nie jest zalogowany
	if (!user) {
		navigate('/login')
		return null
	}

	return (
		<div className="edit-profile-container">
			<form className="edit-profile__form" onSubmit={submitHandler}>
				{error && <div className="edit-profile__error-message">{error}</div>}
				<h3>Edycja konta</h3>
				<label className="edit-profile__label">Avatar Użytkownika</label>
				<div className="edit-profile__picture-selection">
					<img src={profilePicture} alt="Profile" />
					<button type="button" className="edit-profile__button" onClick={generateRandomAvatar}>
						Zmień Avatar
					</button>
				</div>
				<label className="edit-profile__label">Nazwa konta</label>
				{nameError && <div className="edit-profile__error-message">{nameError}</div>}
				<input className="edit-profile__input" type="text" value={name} onChange={handleNameChange} />

				<label className="edit-profile__label">
					Hasło
					<span className="edit-profile__label--optional">(opcjonalne)</span>
				</label>
				<input className="edit-profile__input" type="password" value={password} onChange={handlePasswordChange} />
				{password && passwordStrength && (
					<div className={`edit-profile__password-strength ${passwordStrength.replace(' ', '-').toLowerCase()}`}>
						{passwordStrength}
					</div>
				)}
				<label className="edit-profile__label">
					Opis Profilu
					<span className="edit-profile__label--optional">(opcjonalne)</span>
				</label>
				<div className="edit-profile__textarea-wrapper">
					<textarea
						className="edit-profile__input"
						value={description}
						onChange={handleDescriptionChange}
						placeholder="Cześć, jestem X, coś o mnie..."
					/>
					<div className="edit-profile__char-count">{185 - charCount}</div>
				</div>

				<div className="buttons-grid-flex-x">
					<button className="xxx-xxx-222" type="submit">
						Zaaktualizuj Profil
					</button>
					<Link to="/chat" className="yyy">
						<button className="yyy" type="button">
							Powrót
						</button>
					</Link>
				</div>
			</form>
		</div>
	)
}

export default EditProfile
