import React from 'react'
import './AnnoucmentBar.css'
function AnnoucmentBar() {
	return (
		<div className="annoucment-bar">
			<h5>🎉 Udało się! Nasza 
				platforma jest dostępna dla wszystkich! 🎉</h5>
		</div>
	)
}

export default AnnoucmentBar
