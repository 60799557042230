import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './FriendInfo.css'
import { ReactComponent as DeleteIcon } from '../../../assets/X.svg'
import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog'
import io from 'socket.io-client'

const socket = io(`https://findalike.me`, {
	withCredentials: true,
	extraHeaders: {
		'my-custom-header': 'abcd',
	},
})

const FriendInfo = ({ selectedFriend, onClose }) => {
	const [friendToRemove, setFriendToRemove] = useState(null)
	const [matchInfo, setMatchInfo] = useState(null)
	const [notification, setNotification] = useState('')

	useEffect(() => {
		if (selectedFriend) {
			const fetchMatchInfo = async () => {
				try {
					const { data } = await axios.get(`https://findalike.me/api/friendship/${selectedFriend._id}`, {
						withCredentials: true,
					})
					setMatchInfo(data)
				} catch (error) {
					console.error('Błąd podczas pobierania informacji o dopasowaniu:', error)
				}
			}

			fetchMatchInfo()
		}
	}, [selectedFriend])

	const handleRemoveFriend = async friendId => {
		try {
			await axios.post(`https://findalike.me/api/remove-friend`, { friendId }, { withCredentials: true })
			socket.emit('friendRemoved', { friendId })
			setNotification('Pomyślnie usunięto znajomego')
			setTimeout(() => {
				window.location.reload()
			}, 2000)
		} catch (error) {
			console.error('Błąd podczas usuwania znajomego:', error)
		}
	}

	const confirmRemoveFriend = friend => {
		setFriendToRemove(friend)
	}

	const cancelRemoveFriend = () => {
		setFriendToRemove(null)
	}

	const confirmRemove = () => {
		if (friendToRemove) {
			handleRemoveFriend(friendToRemove._id)
			setFriendToRemove(null)
		}
	}

	return (
		<div className="friend-info__container">
			<div className="friend-info__header-info">
				<button className="friend-info__close-button" onClick={onClose}>
					<DeleteIcon height="25px" />
				</button>
			</div>
			{selectedFriend ? (
				<div className="friend-info__details">
					<div className="friend-info__top">
						<img src={selectedFriend.profilePicture} alt={selectedFriend.name} className="friend-info__picture" />
						<h3>{selectedFriend.name}</h3>
					</div>
					<div className="friend-info__desc">
						<h5 className="friends-list__friends__list__title">Opis</h5>
						<div className="friend-info__desc-border">
							<strong>Opis Znajomego</strong>
							<p className="friend-info__description">
								{selectedFriend?.description ? selectedFriend.description : 'Brak opisu'}
							</p>
						</div>
					</div>
					<div className="friend-info__match-info">
						<h5 className="friends-list__friends__list__title">Problem</h5>
						<div className="friend-info__match-desc-border">
							{matchInfo ? (
								<>
									<p>
										<strong>Kategoria:</strong> {matchInfo.category}
									</p>
									<p>
										<strong>Problem:</strong> {matchInfo.specificIssue}
									</p>
								</>
							) : (
								<p>Ładowanie informacji o problemie...</p>
							)}
						</div>
					</div>
					<div className="friend-info__match-info">
						<h5 className="friends-list__friends__list__title">Statystyki</h5>
						<div className="friend-info__match-desc-border">
							{matchInfo && matchInfo.createdAt ? (
								<>
									<p>
										<strong>Przyjaźń trwa od:</strong> {new Date(matchInfo.createdAt).toLocaleDateString('pl-PL')}
									</p>
								</>
							) : (
								<p>Ładowanie daty przyjaźni...</p>
							)}
						</div>
					</div>
				</div>
			) : (
				<p className="friend-info__friend__choose">Wybierz znajomego</p>
			)}
		</div>
	)
}

export default FriendInfo
