import React from 'react'
import './ConfirmationDialog.css'

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
	return (
		<div className="confirmation-dialog__overlay">
			<div className="confirmation-dialog__content">
				<p>{message}</p>
				<div className='confirmation__dialog__button__container'>
					<button className="confirmation-dialog__button" onClick={onConfirm}>
						Tak
					</button>
					<button className="confirmation-dialog__button" onClick={onCancel}>
						Nie
					</button>
				</div>
			</div>
		</div>
	)
}

export default ConfirmationDialog
