import React, { useState } from 'react'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import './ResetPassword.css'
import { toast } from 'react-toastify'

const ResetPassword = () => {
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const { token } = useParams()
	const navigate = useNavigate()

	const handleSubmit = async e => {
		e.preventDefault()
		if (password !== confirmPassword) {
			setError('Hasła do siebie nie pasują')

			return
		}

		try {
			const { data } = await axios.post(`https://findalike.me/api/auth/reset-password/${token}`, {
				password,
			})
			setMessage(data.message)
			setError('')
			navigate('/login')
			toast.success('Hasło zostało zmienione')
		} catch (err) {
			setError(err.response.data.message)
			toast.error(err.response.data.message)
			setMessage('')
		}
	}

	return (
		<div className="reset-password__container">
			<div className="reset-password__box">
				<h2>Zresetuj Hasło</h2>
				<form onSubmit={handleSubmit}>
					<div className="label-container-reset">
						<label>Nowe Hasło:</label>
						<input
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
							required
							className="primary__input"
						/>
					</div>
					<div className="label-container-reset">
						<label>Potwierdź Hasło:</label>
						<input
							type="password"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
							required
							className="primary__input"
						/>
					</div>
					<button type="submit" className="secondary__button">
						Zmień Hasło
					</button>
					{message && <p className="reset-password__message">{message}</p>}
					{error && <p className="reset-password__error">{error}</p>}
				</form>
			</div>
		</div>
	)
}

export default ResetPassword
