import React, { useState } from 'react'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import './ForgotPassword.css'
import { ReactComponent as Arrow } from '../../../assets/arrow-right-solid.svg'

const ForgotPassword = () => {
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')

	const handleSubmit = async e => {
		e.preventDefault()
		try {
			const { data } = await axios.post(`https://findalike.me/api/auth/forgot-password`, { email })
			setMessage(data.message)
			setError('')
		} catch (err) {
			if (err.response && err.response.data && err.response.data.message) {
				setError(err.response.data.message)
			} else {
				setError('An unexpected error occurared. Please try again later.')
			}
			setMessage('')
		}
	}

	return (
		<div className="forgot-password__container">
			<div className="forgot-password__content">
				<h2 className="forgot-password__title">Zapomniałeś Hasła?</h2>
				<p className="forgot-password__description">
					Bez obaw, podaj swój Adres email, a my wyślemy Ci link do zresetowania hasła.
				</p>
				<form onSubmit={handleSubmit}>
					<div className="forgot__form__container">
						<label className="forgot-password__label">Email:</label>
						<input
							className="forgot-password__input"
							type="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
							required
							placeholder="Wpisz swój adres email"
						/>
					</div>
					<button className="secondary__button" type="submit">
						Zresetuj Hasło
					</button>
					{message && <p className="forgot-password__message">{message}</p>}
					{error && <p className="forgot-password__error">{error}</p>}
				</form>

				<div className="forgot-password__back">
					<Link to="/login" className="forgot-password__linkback">
						Powrót do logowania <Arrow width="14px" opacity="0.4" />
					</Link>
				</div>
			</div>
		</div>
	)
}

export default ForgotPassword
