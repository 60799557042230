import React from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './context/AuthContext'
import Login from './components/Auth/Login/Login.js'
import Register from './components/Auth/Register/Register.js'
import EditProfile from './components/User/EditProfile/EditProfile.js'
import Chat from './components/Chat/ChatLayout/ChatLayout.js'
import Verify from './components/Auth/Verification/Verification.js'
import Home from './components/Misc/Home/Home.js'
import ForgotPassword from './components/Auth/ForgotPassword/ForgotPassword.js'
import ResetPassword from './components/Auth/ResetPassword/ResetPassword.js'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

const App = () => {
	const { user } = useAuth()
	const location = useLocation()

	const isHomePage = location.pathname === '/'

	return (
		<div className={isHomePage ? 'div-home' : 'div-main'}>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				zIndex={9999}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<Routes>
				<Route path="/login" element={!user ? <Login /> : <Navigate to={user.isVerified ? '/chat' : '/verify'} />} />
				<Route
					path="/register"
					element={!user ? <Register /> : <Navigate to={user.isVerified ? '/chat' : '/verify'} />}
				/>
				<Route
					path="/edit-profile"
					element={user ? user.isVerified ? <EditProfile /> : <Navigate to="/verify" /> : <Navigate to="/register" />}
				/>
				<Route
					path="/chat"
					element={user ? user.isVerified ? <Chat /> : <Navigate to="/verify" /> : <Navigate to="/register" />}
				/>
				<Route
					path="/verify"
					element={user ? user.isVerified ? <Navigate to="/chat" /> : <Verify /> : <Navigate to="/register" />}
				/>
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password/:token" element={<ResetPassword />} />
				<Route path="/" element={<Home />} />
			</Routes>
		</div>
	)
}

export default App
